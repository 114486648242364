import { FCX, ID } from '@models';
import { useUserContext } from '@core/UserContext';
import { IconLock } from '@svg';
import { useState, useMemo, useEffect } from 'react';
import { api } from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import { CountriesSelect, ICountriesSelectOption } from "@datassential/platform-ui-lib";
import './Countries.scss';
import cn from "classnames";

const Countries: FCX = () => {
    const { countries: rawCountries, } = useUserContext();
    const [countries, setCountries] = useState(rawCountries);

    useEffect(() => {
        setCountries(rawCountries);
    }, [rawCountries]);

    const currentCountry = countries.find(c => c.selected);

    const switchCountry = (id: ID) => {
        if (id !== currentCountry?.id) {
            setCountries(countries.map(c => ({
                ...c,
                selected: c.id === id,
            })));
            api.request({
                url: getApiLink('api/User/SetCountry', { countryId: id }),
            }).finally(() => {
                window.location.href = window.location.origin;
            });
        }
    };

    const countriesOptionsPlatform = useMemo(
        (): ICountriesSelectOption[] => countries.map(c => ({
            id: c.id ?? 0,
            shortName: c.alpha3Code ?? '',
            name: c.fullName ?? '',
            className: cn(
                'CountriesSelect__option',
                !c.enabled && 'disabled',
            ),
            children: c.enabled ? null : (
                <div className="CountriesSelect__lock">
                    <IconLock className="CountriesSelect__lock-icon"/>
                </div>
            ),
        })),
        [countries]
    )

    return (
        <>
            {currentCountry && (
                <CountriesSelect
                    options={countriesOptionsPlatform}
                    value={currentCountry.id}
                    setValue={(id) => switchCountry(id ?? 0)}
                    testId={"selectDEMOHeaderCountry"}
                />
            )}
        </>
    );
};

export default Countries;